<template>
  <div>
    <div class="custom-card-box">
      <a-row :gutter="12">
        <a-col :span="8">
          <a-descriptions :column="1" class="custom-descriptions-max-9 custom-descriptions">
            <a-descriptions-item label="记录编号">{{ data.no }}</a-descriptions-item>
            <a-descriptions-item label="状态">{{ data.status }}</a-descriptions-item>
            <a-descriptions-item label="短信模版">{{ data.template_name }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="4">
          <a-descriptions :column="1" class="custom-descriptions">
            <a-descriptions-item label="发送数量">{{ data.total_count }}</a-descriptions-item>
            <a-descriptions-item label="成功数量">{{ data.success_count }}</a-descriptions-item>
            <a-descriptions-item label="失败数量">{{ data.fail_count }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="12">
          <a-descriptions :column="1" class="custom-descriptions">
            <a-descriptions-item label="租户">{{ data.tenant_name }}</a-descriptions-item>
            <a-descriptions-item label="员工">{{ data.user_name }}</a-descriptions-item>
            <a-descriptions-item label="短信内容">
              <span class="custom-word-break-all">{{ data.content }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">{{ data.created_at }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
    </div>

    <div class="custom-card-box" style="margin-top: 20px;">
      <SmsDetailList :record-id="id" />
    </div>
  </div>
</template>

<script>
import { findTenantSmsRecord } from '@/api/sms_record'
import SmsDetailList from '@/views/sms_details/index'
export default {
  name: 'ShowSmsInfo',
  components: {
    SmsDetailList
  },
  data() {
    return {
      data: {},
      recordId: this.$route.params.id,
      previewVisible: false,
      previewImages: []
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findTenantSmsRecord(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    }
  }
}
</script>

