import request from '@/utils/request'

// 查询所有短信记录
export function findTenantSmsRecords(params) {
  return request({
    url: `/tenant/sms`,
    method: 'get',
    params: params
  })
}

// 查询所有短信记录
export function findSystemSmsRecords(params) {
  return request({
    url: `/system/sms`,
    method: 'get',
    params: params
  })
}

// 发送短信
export function sendSms(data) {
  return request({
    url: `/tenant/sms`,
    method: 'post',
    data
  })
}

// 短信记录信息
export function findTenantSmsRecord(id, params) {
  return request({
    url: `/tenant/sms/${id}`,
    method: 'get',
    params: params
  })
}

// 查询短信详情列表
export function findTenantSmsDetails(id, params) {
  return request({
    url: `/tenant/sms/${id}/details`,
    method: 'get',
    params: params
  })
}

export function findSmsTemplateOptions() {
  return request({
    url: `/tenant/sms/template/options`,
    method: 'get'
  })
}
