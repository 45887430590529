<template>
  <div>
    <search @submit="submitSearch" />

    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findTenantSmsDetails } from '@/api/sms_record'
import Search from '@/views/sms_details/Search'

export default {
  name: 'TenantSmsDetailList',
  components: {
    Pagination,
    Search
  },
  props: {
    recordId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowSendModal: false,
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '手机号',
          dataIndex: 'phone_number'
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '发送时间',
          dataIndex: 'created_at'
        }
      ]
    }
  },
  methods: {
    showSendModal() {
      this.isShowSendModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantSmsDetails(this.recordId, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
